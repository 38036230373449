.Construction {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: grey;
}

.Construction-img {
    max-width: 200px;
    margin-bottom: 20px;
}

.Construction-text {
    color: white;
    font-weight: bold;
    font-size: large;
}

